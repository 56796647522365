<template>
  <div class="mt-5" v-if="userDashboards.length === 0">
    <Button
      class="btn btn-primary py-1 py-md-2 px-2 px-md-3 ls-5 font-size-xSmall font-size-md-regular"
      v-b-modal="'request-dashboard-modal'"
    > Solicite aqui seu dashboard </Button>

    <RequestDashboardModal
      buttonText="Solicitar Dashboard"
      headerTitle="Quais as vantagens de usar o dashboard?"
      headerDescription="Aqui listamos algumas vantagens em usar o Dashboard"
    />
  </div>

  <div class="mt-5" v-else>
    <router-link
      :to="{ name: 'dashboards.index' }"
      class="btn btn-primary"
    >
      Ver Dashboards
    </router-link>
  </div>
</template>

<script>
import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import Button from '@/components/common/Button/Button'
import RequestDashboardModal from '@/components/common/Modal/RequestDashboardModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'RedirectToDashboardsButton',
  props: ['text'],
  components: {
    Button,
    RequestDashboardModal
  },
  data () {
    return {
      userDashboards: [],
      loader: false
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  watch: {
    user (newUser, oldUser) {
      if (newUser) this.checkUserDashboards()
    }
  },
  created () {
    this.checkUserDashboards()
  },
  methods: {
    async checkUserDashboards () {
      if (!this.user) return

      this.loader = true
      try {
        const response = await DashboardsService.getDashboards({
          companyId: this.user.company.id,
          page: 1
        })
        this.userDashboards = response.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
